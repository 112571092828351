// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX, withOptimizedAppearEffect } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
const ImageWithFXWithOptimizedAppearEffect = withOptimizedAppearEffect(withFX(Image));

const enabledGestures = {QmB0k1oGh: {hover: true}};

const cycleOrder = ["QmB0k1oGh", "obT35_1Gg", "iEzHKoiu9"];

const serializationHash = "framer-EzbIo"

const variantClassNames = {iEzHKoiu9: "framer-v-1kp53d7", obT35_1Gg: "framer-v-1u964t6", QmB0k1oGh: "framer-v-1t42hlo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const animation1 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "QmB0k1oGh", Phone: "obT35_1Gg", Tablet: "iEzHKoiu9"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "QmB0k1oGh"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "QmB0k1oGh", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://api.whatsapp.com/send/?phone=5531998302299&text=Ol%C3%A1%2C+vim+pelo+site+e+preciso+de+orienta%C3%A7%C3%A3o+jur%C3%ADdica%21&type=phone_number&app_absent=0"} motionChild nodeId={"QmB0k1oGh"} scopeId={"mH8V7suy5"}><ImageWithFXWithOptimizedAppearEffect {...restProps} {...gestureHandlers} __framer__presenceAnimate={animation} __framer__presenceInitial={animation1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 3516, pixelWidth: 3500, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/XQJlbfCOdGLsWxsO8yPn0IFpzM.png", srcSet: "https://framerusercontent.com/images/XQJlbfCOdGLsWxsO8yPn0IFpzM.png?scale-down-to=1024 1019w,https://framerusercontent.com/images/XQJlbfCOdGLsWxsO8yPn0IFpzM.png?scale-down-to=2048 2038w,https://framerusercontent.com/images/XQJlbfCOdGLsWxsO8yPn0IFpzM.png 3500w"}} className={`${cx(scopingClassNames, "framer-1t42hlo", className, classNames)} framer-qwow62`} data-framer-appear-id={"1t42hlo"} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"QmB0k1oGh"} optimized ref={refBinding} style={{...style}} {...addPropertyOverrides({"QmB0k1oGh-hover": {"data-framer-name": undefined}, iEzHKoiu9: {"data-framer-name": "Tablet"}, obT35_1Gg: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EzbIo.framer-qwow62, .framer-EzbIo .framer-qwow62 { display: block; }", ".framer-EzbIo.framer-1t42hlo { cursor: pointer; height: 59px; position: relative; text-decoration: none; width: 58px; }", ".framer-EzbIo.framer-v-1u964t6.framer-1t42hlo { cursor: unset; height: 69px; width: 68px; }", ".framer-EzbIo.framer-v-1kp53d7.framer-1t42hlo { cursor: unset; height: 67px; width: 68px; }", ".framer-EzbIo.framer-v-1t42hlo.hover.framer-1t42hlo { height: 73px; width: 72px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 59
 * @framerIntrinsicWidth 58
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"obT35_1Gg":{"layout":["fixed","fixed"]},"iEzHKoiu9":{"layout":["fixed","fixed"]},"NAriR0o5V":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermH8V7suy5: React.ComponentType<Props> = withCSS(Component, css, "framer-EzbIo") as typeof Component;
export default FramermH8V7suy5;

FramermH8V7suy5.displayName = "bota";

FramermH8V7suy5.defaultProps = {height: 59, width: 58};

addPropertyControls(FramermH8V7suy5, {variant: {options: ["QmB0k1oGh", "obT35_1Gg", "iEzHKoiu9"], optionTitles: ["Desktop", "Phone", "Tablet"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramermH8V7suy5, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})